import React from "react";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";

import asyncComponent from "util/asyncComponent";
import {useAppAbility} from "../util/Can";
import {Button, Result} from "antd";
import {useSelector} from "react-redux";


const Unauthorized = () => {
  const {authUser} = useSelector(({auth}) => auth);
  const history = useHistory();
 
  return (
    <Result
      status="403"
      title="403"
      subTitle="Bạn không có quyền truy cập trang này!"
      extra={<Button type="primary" onClick={() => {
        history.push(`/profile/${authUser.profileId}`)
      }}>Đến trang cá nhân</Button>}
    />
  );
};

const IncompleteProfile = () => {
  const history = useHistory();
  const {authUser, initURL} = useSelector(({auth}) => auth);
  return (
    <Result
      status="warning"
      title="Thông tin chưa đầy đủ"
      subTitle="Bạn cần cập nhật thông tin cá nhân trước khi truy cập vào trang này."
      extra={
        <Button
          type="primary"
          onClick={() =>   history.push(`/profile/${authUser.profileId}`)}
        >
          Cập nhật thông tin
        </Button>
      }
    />
  );
};


function usePathInfo() {
  const {pathname} = useLocation();
  const pathParts = pathname.split("/").filter(s => !!s.length);
  const type = pathParts[0]
  const id = pathParts[1]
  return {type, id}
}

const ProtectedRoute = ({component: Component, action, subject, ...rest}) => {
  const ability = useAppAbility();
  const pathInfo = usePathInfo()
  const viewDetail = !!pathInfo.id;
  let canAccess = ability.can('access', viewDetail ? pathInfo : pathInfo.type);
  if (viewDetail && !canAccess && pathInfo.type == "profile") {
    canAccess = ability.can('access', `students`);
  }

  const { isProfileComplete } = useSelector(({ auth }) => auth);
  const isProfileRoute = pathInfo.type === "profile" && pathInfo.id;


  return (
  
    <Route
    {...rest}
    render={(props) => {
      if (isProfileRoute) {
        return canAccess ? <Component {...props} /> : <Unauthorized />;
      } else {
        return canAccess ? isProfileComplete ? <Component {...props} /> : <IncompleteProfile /> : <Unauthorized />;
      }
    }}
  />
  );
};

const App = ({match}) => {

  return (
    <div className="gx-main-content-wrapper gx-p-3 px-moblie-0 ">
      <Switch>
        <ProtectedRoute path={`${match.url}profile/:id`}
                        component={asyncComponent(() => import('./profile/view'))}/>
        <Route path={`${match.url}profiles`} component={asyncComponent(() => import('./profile'))}/>
        <Route path={`${match.url}student/:id`} component={asyncComponent(() => import('./student/View'))}/>
        <ProtectedRoute path={`${match.url}students`} component={asyncComponent(() => import('./student/'))}/>
        <ProtectedRoute path={`${match.url}group/:id`} component={asyncComponent(() => import('./group/View'))}/>
        <ProtectedRoute path={`${match.url}groups`} component={asyncComponent(() => import('./group/'))}/>
        <ProtectedRoute path={`${match.url}events`} component={asyncComponent(() => import('./event/'))}/>
        <ProtectedRoute path={`${match.url}applications`} component={asyncComponent(() => import('./profileApplication'))}/>
        <ProtectedRoute path={`${match.url}admin`} component={asyncComponent(() => import('./admin'))}/>
        <ProtectedRoute path={`${match.url}interviewer/:id/:eventId`} component={asyncComponent(() => import('./interviewer/View/EventDetail'))}/>
        <ProtectedRoute path={`${match.url}interviewer/:id`} component={asyncComponent(() => import('./interviewer/View/index'))}/>
        <ProtectedRoute path={`${match.url}interviewer`} component={asyncComponent(() => import('./interviewer/'))}/>
        <ProtectedRoute path={`${match.url}checkin/:groupId`} component={asyncComponent(() => import('./checkIn/'))} />
        <ProtectedRoute path={`${match.url}checkins`} component={asyncComponent(() => import('./checkIn/'))}/>
        <Route path={`${match.url}home`} component={asyncComponent(() => import('./home/'))}/>
        <ProtectedRoute path={`${match.url}event/:eventId`}
                        component={asyncComponent(() => import('./event/View'))} />
                        
      </Switch>
    </div>
  );
};

export default App;
