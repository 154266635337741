import {AbilityBuilder, Ability} from '@casl/ability';

export const AppAbility = Ability;
const CAPTAIN_PREFIX = "CAPTAIN_";
export default function defineRulesFor(user) {
  const {can,cannot, rules} = new AbilityBuilder(AppAbility);
  const roles = user.roles || []
  const validRoles = ["ADMIN", "SUPERVISOR"];
  if (roles && roles.some(role => validRoles.includes(role))) {
    can("manage", "all")
    return rules;
  }
  const canManageGroups = roles.filter(r => r.startsWith(CAPTAIN_PREFIX)).map(r => r.replace(CAPTAIN_PREFIX, ""));
  const isCaptain = canManageGroups.length;
  if (isCaptain) {
    // can("access", "group")
    can("access", "profiles")
    can('access', 'event');
    canManageGroups.forEach(groupId => {
      can("access", "group", {id: groupId});
      can("access", "checkin", {id: groupId});
    })
  }
  if (roles?.includes("MANAGEMENT")) {
    can("access", "home")
    can("access", "groups")
    can("access", "students")
    can("access", "events")
    can("access", "profiles")
    can("access", "group")
    can('access', 'checkin')
    can('access', 'checkins')
    can('access', 'event');
  }
  if (roles?.includes("INTERVIEWER")) {
    can("access", "interviewer")
    can("access", "profiles")
    can('access', 'event');
  }
  can('access', 'profile', {id: user.profileId});
  can('access', 'event');
  return rules;
}


export function initAppAbility() {
  return new AppAbility(defineRulesFor({}), {
    detectSubjectType: (object) => object?.type
  });
}

export function updateAbility(ability, user) {
  ability.update(defineRulesFor(user));
}

export const updateViewableProfiles = (ability, ids) => {

}
