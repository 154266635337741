import featherApp, {BACKEND_URL} from "../../feathersjs/feathersjs";

const uploadService = featherApp.service("uploads")


export const deleteFile = async (fileId, uploadType, belongTo) => {
  return uploadService.remove(fileId, {
    query: { uploadType, belongTo } 
  });
};

export const resizeImage = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        const MAX_WIDTH = 1920;
        const aspectRatio = img.width / img.height;
        const width = img.width > MAX_WIDTH ? MAX_WIDTH : img.width;
        const height = width / aspectRatio;

        canvas.width = width;
        canvas.height = height;

        ctx.drawImage(img, 0, 0, width, height);

        let quality = 0.8; 
        const compress = () => {
          canvas.toBlob(
            (blob) => {
              if (blob.size <= 600 * 1024 || quality <= 0.1) {
                const resizedFile = new File([blob], file.name, { type: file.type });
                resolve(resizedFile);
              } else {
                quality -= 0.1;
                compress();
              }
            },
            "image/jpeg",
            quality
          );
        };

        compress();
      };

      img.onerror = () => reject(new Error("Failed to load image"));
    };

    reader.onerror = () => reject(new Error("Failed to read file"));
    reader.readAsDataURL(file);
  });
};

function convertToFormData(formInfos) {
  const formData = new FormData()
  const additionsKeys = Object.keys(formInfos || {});
  for (let i = 0; i < additionsKeys.length; i++) {
    const key = additionsKeys[i];
    formData.append(key, formInfos[key])
  }
  return formData;
}

function prepareFormData(file, additionInfos) {
  return convertToFormData({
    ...additionInfos,
    uri: file,
    fileName: file.name,
    fileType: file.type
  });
}

export const uploadFile = async (file, additionInfos) => {
  const formData = prepareFormData(file, additionInfos);

  const uploadedInfo = await uploadService.create(formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return uploadedInfo.fileId;
}

export const getFileUploadUrl =  (fileId) => {
  return `${BACKEND_URL}/files/${fileId}`
}

export const getUploadedData = async (fileId) => {
  return uploadService.get(fileId)
}
export function convertToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result.replace(/^.*,/, ''));
    reader.onerror = error => reject(error);
  });
}
