import featherApp from "../../feathersjs/feathersjs";
import {withErrorHandler} from "./common";
import {removeNullValue} from "./student";
import _ from "lodash";
import {fetchAllProfiles, sortMapping} from "./profile";
import moment from "moment-timezone";
import { fetchEventCheckInByEventIdAndProfileId } from "./checkin";


const EventService = featherApp.service("events");
const EventAttendanceService = featherApp.service("event-attendance");
const EventInterviewersService = featherApp.service("event-interviewers");
const StatisticsEvents = featherApp.service("statistics-events");
const EventReview = featherApp.service("event-review");
const ReviewInteract = featherApp.service("event-review-interact");
const EventEmails = featherApp.service("event-emails")


export const fetchEvents = withErrorHandler(async (searchData) => {
  const modifiedSearchData = {
    ...searchData,
    query: {
      ...searchData.query,
      isDeleted: false,
    }
  };

  return await EventService.find(modifiedSearchData);
});

export const fetchAllEvents = withErrorHandler(({params, sort, filter}) => {
  if(params) removeNullValue(params)
  if(filter)  removeNullValue(filter)

  const $sort = _.transform(sort, function (result, value, key) {
    result[key] = sortMapping[value]
    return result
  }, {});

  const {current = 1, pageSize: $limit = 1000, eventIds, ...requestParams} = params;
  const handleRequest = transformEventRequestParams(requestParams);
 
  const query = {
    $sort,
    $limit,
    $skip: (current - 1) * $limit,
    ...filter,
    ...handleRequest, 
  };

  if (eventIds && eventIds.length > 0) {
    query.id = { $in: eventIds };  
  }

  const searchData = { query };

  return fetchEvents(searchData);
});

export const transformEventRequestParams = (requestParams) => {
  return _.transform(requestParams, (result, value, key) => {

    if (key === 'academicYear') {
        result[key] = value 
    } 
    else if (key === 'title' && value) {
      result[key] = value ;
  } else if (value) {
      result[key] = value; 
    }

    return result;
  }, {});
};


export const fetchEventByType = withErrorHandler((params) => {
    const searchData = {query: { $limit: 1000, type:params}}
    return fetchEvents(searchData);
  }
);
const today = moment().tz('Asia/Ho_Chi_Minh');
export const convertedTodayToUtc = today.clone().utcOffset(0, true).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]');

export const fetchCurrentEvents = async (requestParams) => {
  const handleRequest = transformEventRequestParams(requestParams);

  return fetchEvents({
    query: {
      from: {
        $lte: convertedTodayToUtc
      },
      to: {
        $gte: convertedTodayToUtc
      },
      ...handleRequest
    }
  });
}
// fetchFutureEvents
export const fetchFutureEvents = (requestParams) => {
  const handleRequest = transformEventRequestParams(requestParams);
  return fetchEvents({
    query: {
      from: {
        $gte: convertedTodayToUtc
      },
      ...handleRequest
    }
  });
}
// fetchPastEvents
export const fetchPastEvents = (requestParams) => {
  const handleRequest = transformEventRequestParams(requestParams);
  return fetchEvents({
    query: {
      to: {
        $lte: convertedTodayToUtc
      },
      ...handleRequest,
      $sort: {id: -1}
    }
  });
}

export const fetchEventCheckedIn = async(profileId,requestParams ) => {
  const { data } = await fetchEventCheckInByEventIdAndProfileId({profileId});
  const eventIds = data.map(item => item.eventId); 
  if (eventIds?.length > 0) {
    return fetchAllEvents({
      params: { 
        eventIds, 
        ...requestParams
      }
    });
  }
}

export const fetchEventReviews = withErrorHandler(async (params) => {
  const res = await EventReview.find(params);
  return res.data;
})

export const createReview = withErrorHandler(async (review) => {
  return EventReview.create(review);
})

export const updateReviewEvent = withErrorHandler(async (id, value) => {
  return await EventReview.patch(id, value);
})


export const fetchReviewByProfileId = withErrorHandler(async (profileId) => {
  return await fetchEventReviews({
    query: {
      profileId: profileId,
    }
  });
})
export const fetchReviewByEvent = withErrorHandler(async (eventId, star) => {
  return await fetchEventReviews({
    query: {
      eventId: eventId,
      ...(star && {rating: star}),
      $sort: {id: -1},
    }
  });
})

//generate create event action
export const createEvent = withErrorHandler(async (event) => {
  return EventService.create(event);
})

//generate update event action
export const updateEvent = withErrorHandler(async (id, event) => {
  return await EventService.patch(id, event);
})
//generate delete event action
export const fetchEvent = withErrorHandler(async (id) => {
  return await EventService.get(id);
})//generate delete event action
export const deleteEvent = withErrorHandler(async (event) => {
  return await EventService.remove(event.id);
})

export const registerEvent = withErrorHandler(async (event) => {
  return EventAttendanceService.create(event);
})
// updateRegisterEvent
export const updateRegisterEvent = withErrorHandler(async (registerId, registerInfo, interviewerId) => {
  return await EventAttendanceService.patch(registerId, {
    registerInfo,
    interviewerId, 
  });
});

export const fetchEventAttendance = withErrorHandler(async (params) => {
  const res = await EventAttendanceService.find(params);
  return res.data;
})
export const fetchEventInterviewers = withErrorHandler(async (params) => {
  const res = await EventInterviewersService.find(params);
  return res;
})

export const fetchEventsAttendance = withErrorHandler(async (params) => {
 return  await EventAttendanceService.find(params);

})

//find event attendance by profile id
export const fetchAttendedEventsByProfileId = withErrorHandler(async (profileId) => {
  return await fetchEventAttendance({
    query: {
      profileId: profileId,
      $limit: 1000,
    }
  });
})
//fetch event attendance by event id and profile id
export const fetchEventAttendanceByEventId = withErrorHandler(async (eventId, profileId) => {
  return await fetchEventAttendance({
    query: {
      eventId: eventId,
      profileId: profileId,
    }
  });
})

export const fetchAllEventAttendanceByEventId = withErrorHandler(async (eventId) => {
  return await fetchEventAttendance({
    query: {
      eventId: eventId,
      $limit: 1000,
    }
  });
})

export const fetchAllEventInterviewersByEventId = withErrorHandler(async (eventId) => {
  return await fetchEventInterviewers({
    query: {
      eventId: eventId,
      $limit: 1000,
    }
  });
})


export const fetchAllEventAttendanceByEventIdWithProTable = withErrorHandler(({updatedParams: params, sort, filter}) => {
  removeNullValue(params);
  removeNullValue(filter);

  const $sort = transformSort(sort);
  const { current = 1, pageSize: $limit = 1000, ...requestParams } = params;
  const handleRequest = transformEventAttendanceRequestParams(requestParams);
  const searchData = {
    query: { $sort, $limit, $skip: (current - 1) * $limit, ...handleRequest, ...filter, }
  };

  return fetchEventsAttendance(searchData);
});

export const transformSort = (sort) => {
  return _.transform(sort, (result, value, key) => {
    const sortOrder = value === 'ascend' ? 'ASC' : 'DESC';
    if (key === 'dateInterview') {
      result['registerInfo.dateInterview'] = sortOrder;
    } else if (key === 'groupId') {
      result['profile.groupId'] = sortOrder; 
    }else if (key === 'firstName') {
      result['profile.firstName'] = sortOrder; 
    } else if (key === 'profileID') {
      result['profile.profileID'] = sortOrder; 
    }else {
      result[key] = sortOrder;
    }
  }, {});
};


export const fetchCountRegister = withErrorHandler(async (id) => {
  return StatisticsEvents.get(id)
})


export const createReviewInteract = withErrorHandler(async (interact) => {
  return ReviewInteract.create(interact);
})

export const fetchEventAttendanceWithSearch = withErrorHandler(async ({ eventId, params, filter }) => {
  removeNullValue(params);
  removeNullValue(filter);

  const { current = 1, pageSize: $limit = 1000, ...requestParams } = params;
  const handleRequest = transformEventAttendanceRequestParams(requestParams);
  
  const searchData = createSearchData(eventId, current, $limit, handleRequest, filter);
  
  return fetchEventsAttendance(searchData);
});


const createSearchData = (eventId, current, $limit, handleRequest, filter) => {
  return {
    query: {
      $limit,
      $skip: (current - 1) * $limit,
      eventId,
      ...handleRequest,
      ...filter,
    }
  };
};
export const transformEventAttendanceRequestParams = (requestParams) => {
  return _.transform(requestParams, (result, value, key) => {
    const profileKeys = ['groupId', 'firstName', 'holyName'];
    const registerInfoKeys = ['passInterview', 'join', 'lunch', 'dateInterview'];

    if (profileKeys.includes(key)) {
      result['profile'] = {
        ...result['profile'],
        [key]: value 
      };
    } 
    else if (registerInfoKeys.includes(key)) {
      result['registerInfo'] = {
        ...result['registerInfo'],
        [key]: { '$eq': value } 
      };
    } else if (value) {
      result[key] = value; 
    }

    return result;
  }, {});
};


export const fetchEventAttendanceByInterviewerId= withErrorHandler(({ updatedParams: params, interviewerId, eventId }) => {
  removeNullValue(params);

  const { current = 1, pageSize: $limit = 1000 } = params;
  
  const searchData = {
    query: {
      $limit,
      $skip: (current - 1) * $limit,
      interviewerId, 
      eventId
    }
  };

  return fetchEventsAttendance(searchData);
});


export const fetchEventInterviewerByInterviewerId= withErrorHandler(({ updatedParams: params, sort, filter }) => {
  removeNullValue(params);

  const { current = 1, pageSize: $limit = 1000, ...requestParams } = params;
  
  const searchData = {
    query: {
      $limit,
      $skip: (current - 1) * $limit,
      ...requestParams
    }
  };

  return fetchEventInterviewers(searchData);
});

//event-email
export const createEventEmail = withErrorHandler(async (mail) => {
  return EventEmails.create(mail);
})

export const fetchEventEmail = withErrorHandler(async ({ updatedParams: params, sort, filter }) => {
  const { current = 1, pageSize: $limit = 1000, ...requestParams } = params;
  
  const searchData = {
    query: {
      $limit,
      $skip: (current - 1) * $limit,
      $sort: {id: -1},
      ...requestParams
    }
  };

  return await EventEmails.find(searchData);
});